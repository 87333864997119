//==================================
// 	Fonts
//==================================
$font-primary:            Poppins, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif !default;         // Primary font: Poppins
$font-secondary:          Poppins, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif !default;         // Secondary font: Poppins :)

//==================================
// 	Default color
//==================================
$default-dark:             #0D233F;
$default-white:            #ffffff;
$default-gray:             #f6f7fb;
$default-text-color:       #242437;
$default-primary:          #0A4185;
$default-success:          #29B475;
$default-info:             #0472FF;
$default-info-hover:       #4787ff;
$default-info-active:      #001d52;
$default-warning:          #FAB237;
$default-danger:           #E42546;
$default-blue-lg:          #0472FF;
$default-alert:            #E42546;
$default-cancel:           #FF9500;

//==================================
// 	Inverse color
//==================================
$inverse-primary:         lighten($default-primary, 30%);
$inverse-success:         lighten($default-success, 45%);
$inverse-info:            lighten($default-info, 45%);
$inverse-warning:         lighten($default-warning, 37%);
$inverse-danger:          lighten($default-danger, 45%);
$inverse-alert:           #FBC02D;
$inverse-cancel:          #FFDFB2;

//==================================
// 	Misc
//==================================
$border-color:            #e6ecf5;
$header-height:           65px;
$offscreen-size:          280px;
$collapsed-size:          70px;
$side-nav-dark:           #313644;
$side-nav-dark-font:      #99abb4;
$side-nav-dark-border:    rgba(120,130,140,0.3);
$default-dark-gray:       #888da8;
$info-panel-background:   #E3F0F3;

//==================================
// 	Header Themes
//==================================
$theme-primary:    $default-primary;
$theme-info:       $default-info;
$theme-success:    desaturate($default-success, 5%);
$theme-danger:     #f53f61;
$theme-dark:       lighten($side-nav-dark, 10%);

//==================================
// 	Social media color
//==================================

$facebook :               #3B579D;
$twitter :                #2CAAE1;
$instagram :              #5d4a3b;
$google-plus :            #dc4a38;
$pinterest :              #c9181f;
$skype :                  #00AAF1;
$linkedin :               #0177b5;
$youtube :                #DE2825;
$yahoo :                  #7b0099;
$rss :                    #f58432;
$dropbox :                #007EE6;
$sound-cloud :            #FF6A22;
$vimeo :                  #1BB6EC;
$bing :                   #ffb900;
$dribbble :               #ea4c89;
$html5 :                  #f16528;
$sharethis :              #50b948;
$tumblr :                 #36465d;
$wordpress :              #0087be;

//==================================
// 	Default Loyal Guru
//==================================

$default-border-gray:     #E6ECF5;
$default-subtitle:        #515365;

//==================================
// 	Revisited color
//==================================
$revisited-10-black:      #E7E7E9;
$revisited-30-black:      #B6B6BC;
$revisited-40-black:      #9E9EA6;
$revisited-50-black:      #858590;
$revisited-70-black:      #555564;
$revisited-90-black:      #242437;
$revisited-light-blue:    #EBF2FF;
$revisited-neo-blue:      #1D8CFA;
$revisited-neo-navy:      #1D1EFA;
$revisited-neo-purple:    #9212FD;
