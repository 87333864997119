.accordion {
	
	.panel-group {
		margin-bottom: 20px;
	}

	.panel-default {
		background-color: $default-white;
		margin-bottom: 15px;
		border: 1px solid $border-color;
		
		>.panel-heading {
			color:  $default-dark;
			background-color: $default-white;
			border-color:  $border-color;
			
			+ .panel-collapse > {
			
				.panel-body {
					border-top-color: $border-color;
					padding: 10px 20px 20px 20px;
				}
			}
		}
	}

	&.border-less {
		.panel-default {
			border: 0px;
			border-bottom: 1px solid $border-color;
			margin-bottom: 0px;
		}
	}

	.panel-heading {
		padding: 0px;
		border-bottom: 0px;
		border-top-left-radius: 0px;
		border-top-right-radius: 0px;
	}

	.panel-title {
		margin: 0px;

		> a {
			padding: 20px;
			display: block;
			color: $default-dark;

			.icon {
				float: right;
				margin-top: 5px; 
				@include transform(rotate(0deg));
				@include transition(all 0.2s ease-in-out); 
			}

			&.collapsed {
				.icon {
					@include transform(rotate(-90deg));
				}
        
        .icon-up-down {
          @include transform(rotate(180deg));
        }
			}
			
			&:hover,
			&:focus {
				text-decoration: none;
			}
		}
	}
}