$revisited-white: #FFFFFF;
$revisited-70-black: #555564;

.p-tooltip {
  
  .p-tooltip-text {  
    font-family: $font-primary;
    font-size: 14px !important;
    color: $revisited-white !important;
    background: $revisited-70-black !important;
    padding: 11px !important;
    border-radius: 6px !important;
  }
  
  &.p-tooltip-top .p-tooltip-arrow {
    border-top-color: $revisited-70-black !important;
  }
  
  &.p-tooltip-bottom .p-tooltip-arrow {
    border-left-color: $revisited-70-black !important;
  }
  
  &.p-tooltip-right .p-tooltip-arrow {
    border-right-color: $revisited-70-black !important;
  }
  
  &.p-tooltip-left .p-tooltip-arrow {
    border-left-color: $revisited-70-black !important;
  }
}