#chat {
	height: 100%;
}

.chat {
	position: relative;
	height: calc(100vh - #{$header-height});
	
	.chat-user-list {
		@include transition(all 0.2s ease-out);
		position: relative;
		left: 0;
		height: calc(100vh - #{$header-height});
		
		.chat-section {
			margin-bottom: 50px;
		
			.chat-title {
				padding: 0px 20px 10px 20px;
			}
		}
		
		.chat-user {
			padding: 12px 20px 18px 20px;
			display: block;
			position: relative;
			
			.thumb-img {
				height: 40px;
				width: 40px;
				border-radius: 50px;
				float: left;
			}
			
			.user-info {
				padding-left: 55px;
				height: 40px;
				
				.user-name {
					display: block;   
					color: $default-dark;
					
					&.pdd-top-5 {
						padding-top: 7px !important;
					}
				}
				
				.prev-chat {
					display: block;   
					font-size: 12px;
					color: lighten($default-text-color,15%);
				}
			}
			
			&:hover,
			&:focus {
				text-decoration: none;
				background-color: $default-gray;
			}
			
			.status {
				position: absolute;
				width: 10px;
				height: 10px;
				right: 20px;
				left: auto;
				top: 50%;
				@include transform(translateY(-50%));
				background-color: $default-white;
				border-radius: 50px;
				border: 3px solid;
				border-color:  $border-color;
				
				&.online {
					border-color: $default-success;
				}
				
				&.no-disturb {
					border-color: $default-danger;
				}
				
				&.away {
					border-color: $default-warning;
				}
			}
		}
	} 
	
	.conversation {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		right: -300px;
		background-color: $default-white;
		@include transition(all 0.1s ease-out);
		
		.conversation-wrapper {
			position: relative;
			height: 100%;
			
			.conversation-header {
				position: relative;
				line-height: 60px;
				height: 60px;
				padding: 0px 15px;
				
				.back {
					position: absolute;
					line-height: 60px;
					left: 15px;
					top: 2px;
					font-size: 18px;
					color: $default-text-color;
					
					&:hover,
					&:focus {
						text-decoration: none;
						color: $default-dark;
					}
				}
				
				.user-name {
					display: block;
					text-align: center;
					font-size: 16px;
				}
				
				.status {
					position: absolute;
					width: 10px;
					height: 10px;
					right: 20px;
					top: 50%;
					@include transform(translateY(-50%));
					background-color: $default-white;
					border-radius: 50px;
					border: 3px solid;
					border-color:  $border-color;
					
					&.online {
						border-color: $default-success;
					}
					
					&.no-disturb {
						border-color: $default-danger;
					}
					
					&.away {
						border-color: $default-warning;
					}
				}
			}
			
			.conversation-body {
				height: calc(100% - 115px);
				padding: 0px 10px;
				position: relative;
				
				.msg {
					display: block;
					@include clearfix;
					margin-bottom: 15px;

					.bubble {
						position: relative;
						display: inline-block;
						padding: 6px 8px;
						border-radius: 4px;
						font-size: 13px;
						
						&.friend {
							background-color: $default-gray;
							color: $default-dark;
							margin-left: 0;
							margin-right: 40px;
							
							&:after {
								content: "";
								position: absolute;
								left: -5px;
								top: 7px;
								border-bottom: 6px solid transparent;
								border-right: 5px solid $default-gray;
								border-top: 6px solid transparent;
							}
						}
						
						&.me {
							background-color: $default-info;
							color: $default-white;
							margin-right: 0;
							margin-left: 40px;
							float: right;
							
							&:after {
								content: "";
								position: absolute;
								right: -5px;
								top: 7px;
								border-bottom: 6px solid transparent;
								border-left: 5px solid $default-info;
								border-top: 6px solid transparent;
							}
						}
					}
				}
			}
			
			.conversation-footer {
				position: relative;
				background-color: $default-white;
				border-top: 1px solid $border-color;
				height: 100%;
				
				.chat-input {
					border: 0px;
					line-height: 50px;
					outline: 0;
					padding: 0px 55px;
					width: 100%;
				}
				
				.upload-btn,
				.sent-btn  {
					border: 0px;
					background-color: transparent;
					color: lighten($default-text-color,20%);
					outline: none;
					font-size: 22px;
					position: absolute;
					top: 8px;
					left: 10px;
					@include transition(all 0.2s ease-out);
					
					&:hover,
					&:focus {
						color: $default-dark;
					}
				}
				
				.sent-btn {
					left: auto;
					right: 20px;
					font-size: 19px;
					top: 10px;
				}
			}
		}
	}
	
	
	&.open {
				
		.chat-user-list {
			left: -300px;
		}
		
		.conversation {
			right: 0px;
		}
	}
}

