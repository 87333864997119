.page-container {
	min-height: 100vh;
	padding-left: $offscreen-size;
	@include transition(all 0.2s ease);
	@include print {
		padding-left: 0px;
	}

	@include screen-laptop {
		padding-left: $collapsed-size;
	}

	.main-content {
		/* padding: 95px 15px 15px 15px;  Original theme */
		padding: 64px 0px 0px 0px;
		min-height: calc(100vh - 60px);

		@include print {
			padding: 10px 0px;
		}
	}
}

.full-container {
	position: absolute;
    top: $header-height;
    left: $offscreen-size;
    right: 0px;
    min-height: calc(100vh - #{$header-height});
    @include transition(all 0.2s ease);

    @include screen-laptop {
		padding-left: $collapsed-size;
		left: 0px;
	}
}

.ng-enter {
    transition: .5s;
    opacity: 0;
}

.ng-enter-active {
    opacity: 1;
}

.is-collapsed {

	.page-container {
		padding-left: $collapsed-size;

		@include screen-laptop {
			padding-left: $offscreen-size;
		}
	}

	.full-container {
		left: $collapsed-size;

		@include screen-laptop {
			left: $offscreen-size;
			padding-left: 0px;
		}
	}
}
