.label,
.tag {
	padding: 4px 10px;
	border-radius: 50px;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;    
}

.label-lg,
.tag-lg {
	font-size: 16px;
    padding: 6px 12px;
}

label {

	&.error {
		color: $default-danger;
	}

	&.success {
		color: $default-success;
	}
}

.label-primary {
	// Change made due to inconsistencies between color palettes in mkt / design / analytics and FRONTEND
	background-color: $inverse-info;
	color: $default-info;
}

.label-success {
	background-color: $inverse-success;
	color: $default-success;
}

.label-info {
	background-color: $inverse-info;
	color: $default-info;
}

.label-extra-info {
  background-color: lighten($revisited-neo-purple, 42%);
  color: darken($revisited-neo-purple, 10%);
}

.label-warning {
	background-color: $inverse-warning;
	color: $default-warning;
}

.label-danger {
	background-color: $inverse-danger;
	color: $default-danger;
}

.label-critical {
  background-color: $default-alert;
  color: $default-white;
}

.label-default {
	background-color: $default-gray;
	color: $default-dark;
}

.label-alert {
  background-color: $inverse-alert;
  color: $default-alert;
}

.label-cancel {
  background-color: $inverse-cancel;
  color: $default-cancel;
}

.tag {
	display: inline;
	font-size: 75%;
    font-weight: bold;
    line-height: 1;
	text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
}

.tag-primary {
	background-color: $default-primary;
	color: $default-white;
}

.tag-success {
	background-color: $default-success;
	color: $default-white;
}

.tag-info {
	background-color: $default-info;
	color: $default-white;
}

.tag-warning {
	background-color: $default-warning;
	color: $default-white;
}

.tag-danger {
	background-color: $default-danger;
	color: $default-white;
}